import { useState } from 'react';

import plimbusVoice3 from '../../MysticalWizardTunes/plimbusVoice3.wav';
import plimbusVoice4 from '../../MysticalWizardTunes/plimbusVoice4.wav';
import plimbusVoice5 from '../../MysticalWizardTunes/plimbusVoice5.wav';

import './results-popup.scss';

function ResultsPopup(props) {
    const [audio] = useState(new Audio());

    const resultDiv = () => {
        audio.volume = 0.35;
        if(props.results.length === 0) {
            audio.src = plimbusVoice3;
            audio.play();
            return <div>Plimbus the Wise is pleased!</div>
        } else if(props.results.length < 10) {
            audio.src = plimbusVoice4;
            audio.play();
            return <div>Salty cards detected!!! You have angered Plimbus, woe be upon ye!</div>
        } else if(props.results.length >= 10) {
            audio.src = plimbusVoice5;
            audio.play();
            return <div>10+ salty cards!!! Plimbus is actually so pissed right now.</div>
        }
    }

    const closeResults = () => {

    }

    return (
        <div className='results-popup-container'>
            <div className='results-popup-header'>
                HERE ARE YOUR SALTY CARDS:
            </div>
            <div className='results-popup-list'>
                {props.results.map(function(x){
                    return (<li key={x}>{x}</li>)
                })}
            </div>
            <div className='results-popup-assessment'>
                <br/>
                {resultDiv()}
                <button className='results-popup-button' onClick={props.closeButtonClicked}>
                    Once more, Plimbus, I implore you!
                </button>
            </div>
        </div>
    );
}

export default ResultsPopup;