import './spooky-fire.scss';
import spookyFire from '../../FuckingSpookyGifs/spookyFire.gif'

function SpookyFire() {

    return (
        <div className='spooky-fire-container'>
            <img className='fire-image' src={spookyFire} alt='spooky fire' />
        </div>
    );
}

export default SpookyFire;