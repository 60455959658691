import './rhystic-header.scss';
import plimbus1 from '../../FuckingSpookyGifs/plimbus1.gif';
import plimbus2 from '../../FuckingSpookyGifs/plimbus2.gif'

function RhysticHeader() {
    return (
        <div className='header-container'>
            <img src={plimbus1} alt='plimbus 1' />
            <div className='header-text'>
                PLIMBUS THE BLAND'S
                <br/>
                SALTAR OF RHYSTIC REMOVAL
            </div>
            <img src={plimbus2} alt='plimbus 2' />
        </div>
    );
}

export default RhysticHeader;