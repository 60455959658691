import { useEffect, useState } from 'react';

import wizardMusic from '../../MysticalWizardTunes/wizardMusic.mp3';
import plimbusVoice1 from '../../MysticalWizardTunes/plimbusVoice1.wav';

import './music.scss';

function Music(props) {   
    const [playMusic, setPlayMusic] = useState(false);

    const toggleMusic = () => {
        if(!playMusic) {
            props.audio.play();
        } else {
            props.audio.pause();
        }
        setPlayMusic(!playMusic);
    }

    useEffect(() => {
        setPlayMusic(true);
        props.audio.src=plimbusVoice1;
        props.audio.volume = 0.35;
        props.audio.play();
        props.audio.onended = function() {
            props.audio.src=wizardMusic;
            props.audio.volume = 0.05;
            props.audio.play();
        }
    },[props.landingClosed, props.audio]);

    return (
        <div className='music-button-container'>
            <button
                className='music-button'
                onClick={ toggleMusic }
            >
                Toggle Wizard Tunes
            </button>
        </div>
    )    
}

export default Music;