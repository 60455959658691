import { useState } from 'react';

import DecklistInput from './Components/DecklistInput/DecklistInput';
import Music from './Components/Music/Music';
import RhysticHeader from './Components/RhysticHeader/RhysticHeader';
import SpookyFire from './Components/SpookyFire/SpookyFire';
import ResultsPopup from './Components/ResultsPopup/ResultsPopup';
import LandingPage from './Components/LandingPage/LandingPage';

import './App.scss';

function App() {
  
  const [landingClosed, setLandingClosed] = useState(false);
  const [resultsOpen, setResultsOpen] = useState(false);
  const [saltyResults, setSaltyResults] = useState();
  const [audio] = useState(new Audio());

  const landingCloseCallback = () => {
    setLandingClosed(true);
  }

  const resultsClosedCallback = () => {
    setResultsOpen(false);
  }

  const assignSaltyResults = (saltyArray) => {
    setSaltyResults(saltyArray);
    setResultsOpen(true);
  }

  return (
    <div className='App' id='rhystic-app'>
      <LandingPage enterButtonClicked={landingCloseCallback}/>
      <Music audio={audio} landingClosed={landingClosed} resultsOpen={resultsOpen} />
      <RhysticHeader />
      <DecklistInput passSaltyResults={assignSaltyResults} />
      <SpookyFire />
      {resultsOpen && <ResultsPopup results={saltyResults} closeButtonClicked={resultsClosedCallback} />}
    </div>
  );
}

export default App;
