import { useEffect, useState } from 'react';

import './decklist-input.scss';

function DecklistInput(props) {
    const [saltList, setSaltList] = useState([]);
    const [inputString, setInputString] = useState('');

    useEffect(() => {
        fetch("https://json.edhrec.com/pages/top/salt.json")
        .then(response => response.json())
        .then(
            (result) => {
                setSaltList(result.container.json_dict.cardlists[0].cardviews);
            }
        )
    }, [])

    const sanitizeInputList = (inList) => {
        let retList = [];
        inList.forEach(name => {
            let tempName = name.trim();
            let parenIndex = tempName.indexOf('(');

            if(!isNaN(tempName.charAt(0))) {
                tempName = tempName.slice(2);
            }
            if(parenIndex > 0) {
                tempName = tempName.slice(0, (parenIndex-3));
            }
            tempName=tempName.replace(/,/g, '');
            tempName=tempName.replace(/\s/g, '-');
            tempName=tempName.toLowerCase();
            
            retList.push(tempName);
        })
        return retList;
    }

    const checkForSalt = () => {
        let inputList = inputString.split(/\r?\n/);
        let sanitizedInputList = sanitizeInputList(inputList);
        let saltObject = [];
        saltList.forEach(e => {
            let tempObj = {
                'name': e.name,
                'saniName': e.sanitized
            }
            saltObject.push(tempObj);
        })

        let filteredSaniList = saltObject.map(e => e.saniName).filter(e => sanitizedInputList.includes(e));

        let finalList = [];
        saltObject.forEach(e => {
            filteredSaniList.forEach(f => {
                if(f === e.saniName) {
                    finalList.push(e.name);
                }
            })
        })

        props.passSaltyResults(finalList);
    }

    return (
        <div className='decklist-input-container'>
            <div className='decklist-input-header'>"ENTER YOUR CARDS, IF YOU DARE..."</div>
            <div className='decklist-input-body'>
                <textarea
                    className='decklist-input-field'
                    value={inputString}
                    onInput={e => setInputString(e.target.value)} 
                />
                <button 
                    className='decklist-input-button' 
                    onClick={ checkForSalt }
                >
                        LAY YOUR DECK ATOP THE UNHOLY SALTAR
                </button>
            </div>
        </div>
    );
}

export default DecklistInput;