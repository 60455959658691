import './landing-page.scss';

function LandingPage(props) {

    const closeLanding = () => {
        document.getElementById('landing').style.display = 'none';
        props.enterButtonClicked();
    };

    return(
        <div className='landing-page-container' id='landing'>
            <div className='landing-page-body'>
                <div className='landing-text'>
                    DARE YOU ENTER...
                <br/>
                    PLIMBUS' TOWER...?
                </div>
                <button className='landing-button' onClick={closeLanding}>
                    My deck, and my life, are forfeit to you, Unsalted One...
                </button>
            </div>
        </div>
    );
}

export default LandingPage;